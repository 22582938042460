import dataEntry from '../../assets/iconpng/newicons/keyboard.png'
import kitchinIcon from '../../assets/iconpng/newicons/kitchen.png'
import admin from '../../assets/iconpng/newicons/admin.png'
import cooking from '../../assets/iconpng/newicons/cooking.png'
import delivery from '../../assets/iconpng/newicons/delivery.png'
import print from '../../assets/iconpng/newicons/print.png'
import notchose from '../../assets/iconpng/newicons/notchose.png'
import meal from '../../assets/iconpng/newicons/meal.png'
import users from '../../assets/iconpng/newicons/users.png'
import discount from '../../assets/iconpng/newicons/discount.png'
import locationIcon from '../../assets/iconpng/newicons/map.png'
import filesIcon from '../../assets/iconpng/newicons/subscribe.png'
import boxIcon from '../../assets/iconpng/newicons/box.png'
import addIcon from '../../assets/iconpng/newicons/add.png'
import edit from '../../assets/iconpng/newicons/edit.png'
import img from '../../assets/iconpng/newicons/img.png'


import shop from '../../assets/iconpng/shop.png'
import subscribe from '../../assets/iconpng/subscribe.png'
import chefIcon from '../../assets/iconpng/gray/chef.png'
import tableIcon from '../../assets/iconpng/newicons/database.png'
import priceIcon from '../../assets/iconpng/gray/price.png'

const navs={
   mainNav:
   [
    {id:1,pageNameEn:"kitchin",pageNameAr:"المطبخ",icon:kitchinIcon,dispatch:"SetNavKitchin"},
    {id:2,pageNameEn:"data entry",pageNameAr:"ادخال البيانات",icon:dataEntry,dispatch:"setDataEntry"},
    {id:3,pageNameEn:"admin",pageNameAr:"الادمن",icon:admin,dispatch:"setadmin"},

  
],
    

// nav bar 2
chefNav2:[
   {id:1,pageNameEn:"add table meals",pageNameAr:"اضافة جدول الوجبات",icon:addIcon,pageLink:"setCompanyNav3"},
   {id:2,pageNameEn:"table meals",pageNameAr:"جدول الوجبات",icon:tableIcon,pageLink:"setCompanyNav3"},
],
companyNav2:
[{id:1,pageNameEn:"company",pageNameAr:"الشركات",icon:users,pageLink:"setCompanyNav3"},
{id:2,pageNameEn:"shop",pageNameAr:"الافرع",icon:subscribe,pageLink:"Shop"}, ],

adminNav2:
[
   {id:1,pageNameEn:"meals",pageNameAr:"الوجبات",icon:users,pageLink:"setitemsNav3"},
   // {id:3,pageNameEn:"category",pageNameAr:"الفئات",icon:chefIcon,pageLink:"categoryNav3"},
   {id:2,pageNameEn:"chef",pageNameAr:"الشيف",icon:cooking,pageLink:"chefNav3"},


],



   dataEntryNav2:
   [{id:1,pageNameEn:"users",pageNameAr:"المستخدمين",icon:users,pageLink:"setUsersNav3"},
   {id:2,pageNameEn:"Subscribes",pageNameAr:"الاشتراكات",icon:filesIcon,pageLink:"setSubscribeNav3"}, 
   {id:3,pageNameEn:"packages",pageNameAr:"الباقات",icon:boxIcon,pageLink:"setPackagesNav3"}, 
   {id:4,pageNameEn:"areas",pageNameAr:"المناطق",icon:locationIcon,pageLink:"setAreasNav3"}, 
   {id:5,pageNameEn:"discount codes",pageNameAr:"اكواد الخصم",icon:discount,pageLink:"discountCodesNav3"}, 

   //{id:5,pageNameEn:"versions",pageNameAr:"الاصدارات",icon:subscribe,pageLink:"setVersionsNav3"}, 




],

   countryNav2:[
    {id:1,pageNameEn:"Country",pageNameAr:"الدول",icon:users,pageLink:"setCountryNav3",setActive:1},
    {id:2,pageNameEn:"citys",pageNameAr:"المدن",icon:users,pageLink:"Citys",setActive:2},
   ],

   KitchinNav2:[ 
   //  {id:1,pageNameEn:"Kitchin",pageNameAr:"المطبخ",icon:shop,pageLink:"Kitchin"}
   //  ,{id:1,pageNameEn:"Meals",pageNameAr:"الوجبات",icon:boxIcon,pageLink:"setitemsNav3"},
    {id:2,pageNameEn:"chef",pageNameAr:"الشيف",icon:cooking,pageLink:"chefNav4"},
   //  {id:3,pageNameEn:"category",pageNameAr:"الفئات",icon:chefIcon,pageLink:"categoryNav3"},
    {id:4,pageNameEn:"deleviry",pageNameAr:"التوصيل",icon:delivery,pageLink:"deleviryNav3"},
    {id:5,pageNameEn:"print",pageNameAr:"الطباعة",icon:print,pageLink:"PrintVav3"},


   ],




    // navBar3
    countryNav3:[
        {id:1,pageNameEn:"AddCountry",pageNameAr:"اضافة دولة",icon:addIcon,pageLink:"AddCountry",setActive:1},
        {id:2,pageNameEn:"edit country",pageNameAr:"تعديل او البحث عن دولة",icon:edit,pageLink:"SearchCountry",setActive:2},
       ],


   companyNav3:[
   {id:1,pageNameEn:"add company",pageNameAr:"اضافة شركة",icon:users,pageLink:"AddCompany",setActive:1},
   {id:2,pageNameEn:"edit country",pageNameAr:"تعديل او البحث عن شركة",icon:edit,pageLink:"SearchCountry",setActive:2},
   ],

   chefNav3:[
      // {id:1,pageNameEn:"add table meals",pageNameAr:"اضافة جدول الوجبات",icon:addIcon,pageLink:"AddTableMeals"},
      
      
      
      // {id:2,pageNameEn:"table meals",pageNameAr:"جدول الوجبات",icon:tableIcon,pageLink:"MealsAnalysis"},
      // {id:3,pageNameEn:"Add Meals Not Select",pageNameAr:"اضافة وجبات لمن لم يختار",icon:tableIcon,pageLink:"AddMealsNotSelect"},
      {id:4,pageNameEn:"Add chef category",pageNameAr:"اضافة كاتيقوري شيف",icon:tableIcon,pageLink:"AddCategoryChef"},
      {id:5,pageNameEn:"Edit chef category",pageNameAr:"تعديل كاتيقوري شيف",icon:edit,pageLink:"SearchCategoryChef"},
      
      
      
      
      // {id:6,pageNameEn:"Add Not select Table",pageNameAr:"اضافة جدول وجبات من لم يختار",icon:addIcon,pageLink:"AddTableNotSelect"},

   ],

   chefNav4:[
      // {id:1,pageNameEn:"add table meals",pageNameAr:"اضافة جدول الوجبات",icon:addIcon,pageLink:"AddTableMeals"},
      {id:2,pageNameEn:"table meals",pageNameAr:"جدول الوجبات",icon:meal,pageLink:"MealsAnalysis"},
      {id:3,pageNameEn:"Add Meals Not Select",pageNameAr:"اضافة وجبات لمن لم يختار",icon:notchose,pageLink:"AddMealsNotSelect"},
      // {id:4,pageNameEn:"Add chef category",pageNameAr:"اضافة كاتيقوري شيف",icon:tableIcon,pageLink:"AddCategoryChef"},
      // {id:5,pageNameEn:"Edit chef category",pageNameAr:"تعديل كاتيقوري شيف",icon:tableIcon,pageLink:"SearchCategoryChef"},
      // {id:6,pageNameEn:"Add Not select Table",pageNameAr:"اضافة جدول وجبات من لم يختار",icon:tableIcon,pageLink:"AddTableNotSelect"},

   ],

   userNav3:[
      {id:1,pageNameEn:"add new user",pageNameAr:"اضافة مستخدم جديد",icon:addIcon,pageLink:"AddUser"},
      {id:2,pageNameEn:"users table",pageNameAr:"جدول المستخدمين",icon:tableIcon,pageLink:"SearchUser"},
   ],

   subscribeNav3:[
      {id:1,pageNameEn:"add new subscribe",pageNameAr:"اضافة اشتراك جديد",icon:addIcon,pageLink:"AddSubscribe"},
      {id:2,pageNameEn:"subscribe table",pageNameAr:"جدول الاشتراكات",icon:tableIcon,pageLink:"SearchSubscribe"},

      

   ],

   packagesNav3:[
      {id:1,pageNameEn:"add new package",pageNameAr:"اضافة باقة جديدة",icon:addIcon,pageLink:"AddPackage"},
      {id:2,pageNameEn:"package table",pageNameAr:"الباقات",icon:tableIcon,pageLink:"SearchPackages"},
      {id:3,pageNameEn:"add package price",pageNameAr:"اضافة سعر للباقة",icon:discount,pageLink:"AddPackagePrice"},
      {id:4,pageNameEn:"package price table",pageNameAr:"جدول اسعار الباقات",icon:tableIcon,pageLink:"SearchPrice"},

   ],


   areasNav3:[
      {id:1,pageNameEn:"add new area",pageNameAr:"اضافة منطقة جديدة",icon:addIcon,pageLink:"AddArea"},
      {id:2,pageNameEn:"areas table",pageNameAr:"المناطق",icon:tableIcon,pageLink:"SearchAreas"},
   ],

   versionsNav3:[
      {id:1,pageNameEn:"add new version",pageNameAr:"اضافة اصدار جديد",icon:addIcon,pageLink:"AddVersion"},
      {id:2,pageNameEn:"version table",pageNameAr:"جدول الاصدارات",icon:tableIcon,pageLink:"SearchVersions"},      
   ],


   itemsNav3:[
      {id:1,pageNameEn:"add new item img",pageNameAr:"اضافة صورة للصنف جديد",icon:img,pageLink:"AddItemsImg"},
      {id:2,pageNameEn:"edit item img",pageNameAr:"تعديل صورة الصنف",icon:edit,pageLink:"SearchImgItem"},

      {id:3,pageNameEn:"add item",pageNameAr:"اضافة اصناف",icon:addIcon,pageLink:"AddItems"},   
      {id:4,pageNameEn:"items table",pageNameAr:" جدول الاصناف",icon:tableIcon,pageLink:"SearchItems"},       
   ],

   categoryNav3:[
      {id:1,pageNameEn:"add new category ",pageNameAr:"اضافة  فئة جديد",icon:addIcon,pageLink:"AddCategory"},
      {id:2,pageNameEn:"categorys table",pageNameAr:"جدول الفئات",icon:tableIcon,pageLink:"SearchCategory"},
   ],

   discountCodesNav3:[
      {id:1,pageNameEn:"add new code ",pageNameAr:"اضافة  كود خصم جديد",icon:addIcon,pageLink:"AddDiscountCode"},
      {id:2,pageNameEn:"discount codes table ",pageNameAr:"جدول الاكواد",icon:tableIcon,pageLink:"SearchDiscountCodes"},

   ],


   deleviryNav3:[
      {id:1,pageNameEn:"deleviry",pageNameAr:"التوصيل",icon:delivery,pageLink:"Deleviry"},

   ],

   PrintVav3:[
      {id:1,pageNameEn:"print meals",pageNameAr:"طباعة الوجبات",icon:print,pageLink:"PrintMeals"},

   ]





}
    export default navs;